// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyA4MKvMgMdvAeWvYN8LyKouCtIvuPJVjY4",
    authDomain: "high-office-303520.firebaseapp.com",
    projectId: "high-office-303520",
    storageBucket: "high-office-303520.appspot.com",
    messagingSenderId: "437103449384",
    appId: "1:437103449384:web:cc2c73c13d931969406c1d",
    measurementId: "G-FKZJ62BCK0"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
