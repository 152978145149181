import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Dashboard', url: '/dashboard', icon: '' },
    { title: 'Registrieren', url: '/sign-up', icon: '' },
    { title: 'Einloggen', url: '/sign-in', icon: '' },
    { title: 'Ausloggen', url: '/sign-out', icon: '' }
  ];
  constructor() {}
}
